import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getData = createAsyncThunk('appBrands/getData', async (params) => {
  const response = params
    ? await axios.get(
        `/api/v1/company/brand/?page_size=${params.perPage}&page=${
          params.page
        }&ordering=${params.sort === 'desc' ? '-' : ''}${params.sortColumn}${
          params.search && `&search=${params.search}`
        }`,
        params
      )
    : await axios.get(`/api/v1/company/brand/`, params);
  // console.log(response.data);
  return {
    params,
    allData: response.data,
    data: response.data.results,
    totalRows: response.data.count,
  };
});

export const appBrandsSlice = createSlice({
  name: 'appBrands',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      //state.results = action.payload.results;
      state.allData = action.payload.allData;
      state.total = action.payload.totalRows;
      state.params = action.payload.params;
    });
  },
});

export default appBrandsSlice.reducer;
