// import { API_LINK } from '../.env';
// console.log(process.env.API_LINK);
// console.log(NODE_ENV);
// const env = require('env');
// console.log(process.env.REACT_APP_API_LINK);
// export const api = 'https://www.splay.entexy.com';
import {
  ChevronDown,
  ChevronsDown,
  ChevronsUp,
  ChevronUp,
  Minus,
  XOctagon,
} from 'react-feather';

export const api = `${process.env.REACT_APP_API_LINK}`;
export const default_logo = `${api}/media/photos/brand/default_brand.svg`;
export const default_profile = `${api}/media/photos/profile/default_profile.jpg`;

export const roles = {
  superAdmin: 'Супер администратор',
  salesManager: 'Менеджер по продажам',
  leadGenerator: 'Лидогенератор',
};

export const activeViewColor = '#7367f0';
export const inactiveViewColor = '#6e6b7b';
export const BACKLOG = 'BackLog';

export const isNotEmpty = (obj) => {
  for (let key in obj) {
    return true;
  }
  return false;
};

export const renderEmail = () => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < 5; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text + '@entexy.com';
};

export const priorityOptions = [
  {
    value: 1,
    label: (
      <>
        <i>
          <XOctagon size={20} className="text-danger me-1" />
          Critical
        </i>
      </>
    ),
  },
  {
    value: 2,
    label: (
      <>
        <i>
          <ChevronsUp size={20} className="text-danger me-1" />
          Highest
        </i>
      </>
    ),
  },
  {
    value: 3,
    label: (
      <>
        <i>
          <ChevronUp size={20} className="text-warning me-1" />
          High
        </i>
      </>
    ),
  },
  {
    value: 4,
    label: (
      <>
        <i>
          <Minus size={20} className="text-warning me-1" />
          Medium
        </i>
      </>
    ),
  },
  {
    value: 5,
    label: (
      <>
        <i>
          <ChevronDown size={20} className="text-primary me-1" />
          Low
        </i>
      </>
    ),
  },
  {
    value: 6,
    label: (
      <>
        <i>
          <ChevronsDown size={20} className="text-primary me-1" />
          Chill
        </i>
      </>
    ),
  },
];
export const priorityOptionsIcons = [
  {
    value: 1,
    label: (
      <>
        <i>
          <XOctagon size={20} className="text-danger" />
        </i>
      </>
    ),
    title: 'Critical',
  },
  {
    value: 2,
    label: (
      <>
        <ChevronsUp size={20} className="text-danger" />
      </>
    ),
    title: 'Highest',
  },
  {
    value: 3,
    label: (
      <>
        <i>
          <ChevronUp size={20} className="text-warning" />
        </i>
      </>
    ),
    title: 'High',
  },
  {
    value: 4,
    label: (
      <>
        <i>
          <Minus size={20} className="text-warning" />
        </i>
      </>
    ),
    title: 'Medium',
  },
  {
    value: 5,
    label: (
      <>
        <i>
          <ChevronDown size={20} className="text-primary" />
        </i>
      </>
    ),
    title: 'Low',
  },
  {
    value: 6,
    label: (
      <>
        <i>
          <ChevronsDown size={20} className="text-primary" />
        </i>
      </>
    ),
    title: 'Chill',
  },
];

export const orderStatusesTitles = {
  1: { order: 'Входящие', desk: 'Входящие' },
  2: { order: 'В оценке', desk: 'В оценке' },
  3: { order: 'Обсуждение КП', desk: 'Обсуждение КП' },
  4: { order: 'Согласование договора', desk: 'Согл.договора' },
};

export const contactWay = [
  { text: 'Позвонил по телефону', value: 1 },
  { text: 'WhatsApp', value: 2 },
  { text: 'Email', value: 3 },
  { text: 'Telegram', value: 4 },
  { text: 'Заявка с сайта', value: 5 },
];
