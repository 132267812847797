import axios from "axios";
import { addBrand } from "./brandStore";

const SAVE_BRANDS_LIST = 'SAVE_BRANDS_LIST';
export const saveBrandsList = (value) => ({
    type: SAVE_BRANDS_LIST,
    payload: value,
});
export const getBrandsList = () => async(dispatch) => {
    const response = await axios(`/api/v1/company/brand/list_of_titles`, { Authorization: 'Bearer ' + localStorage.getItem("accessToken") });
    dispatch(saveBrandsList(response.data.results));
}

const initialState = {
    brandsList: [],
};

const saveBrandsListReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_BRANDS_LIST:
            {
                return {
                    ...state,
                    brandsList: action.payload,
                };
            }
        default:
            {
                return state;
            }
    }
};

export default saveBrandsListReducer;