import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getData = createAsyncThunk('appTeam/getData', async (params) => {
  const response = await axios.get(
    `/api/v1/team/employee/?is_archive=${params.is_archive}&page_size=${params.perPage}&page=${params.page}&ordering=${params.sort === 'desc' ? '-' : ''}${params.sortColumn}${params.search && `&search=${params.search}`}`,
  );

  return {
    params,
    allData: response.data,
    data: response.data.results,
    totalRows: response.data.count,
  };
});

export const appTeamSlice = createSlice({
  name: 'appTeam',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.allData = action.payload.allData;
      state.total = action.payload.totalRows;
      state.params = action.payload.params;
    });
  },
});

export default appTeamSlice.reducer;
