// ** Auth Endpoints
export default {
    // loginEndpoint: '/jwt/login',
    // registerEndpoint: '/jwt/register',
    // refreshEndpoint: '/jwt/refresh-token',
    // logoutEndpoint: '/jwt/logout',
    loginEndpoint: `/auth/jwt/create/`,
    refreshEndpoint: `/auth/jwt/refresh/`,

    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',
    // tokenType: '',

    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
    // storageTokenKeyName: 'access',
    // storageRefreshTokenKeyName: 'refresh'
};