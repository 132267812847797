const SAVE_EMAIL = 'SAVE_EMAIL';
export const saveEmail = (value) => ({
    type: SAVE_EMAIL,
    payload: value,
});
const initialState = {
    email: '',
};

const saveEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_EMAIL:
            {
                return {
                    ...state,
                    email: action.payload,
                };
            }
        default:
            {
                return state;
            }
    }
};

export default saveEmailReducer;