// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';

export const getProjects = createAsyncThunk(
  'appProjects/getData',
  async (params) => {
    const response = await axios.get(
      `/api/v1/project/?page_size=${params.perPage}&page=${
        params.page
      }&ordering=${params.sort === 'desc' ? '-' : ''}${
        params.brand && `&brand_domain=${params.brand}`
      }${params.sortColumn}${params.search ? `&search=${params.search}` : ''}`,
      params
    );
    return {
      params,
      allData: response.data,
      data: response.data.results,
      totalRows: response.data.count,
    };
  }
);

// export const deleteProject = createAsyncThunk('appProjects/deleteInvoice', async (id, { dispatch, getState }) => {
//   await axios.delete('/apps/invoice/delete', { id })
//   await dispatch(getData(getState().invoice.params))
//   return id
// })

export const appProjectsSlice = createSlice({
  name: 'appProjects',
  initialState: {
    data: [],
    total: 1,
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.allData = action.payload.allData;
      state.total = action.payload.totalRows;
    });
  },
});

export default appProjectsSlice.reducer;
