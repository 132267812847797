import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getData = createAsyncThunk('appClients/getData', async (params) => {
    const response = await axios.get(
      `/api/v1/customer/?page_size=${params.perPage}&page=${params.page}&ordering=${params.sort === 'desc' ? '-' : ''}${params.sortColumn}${params.brand && `&brand_domain=${params.brand}`}${params.search ? `&search=${params.search}` : ''}`
    );

    return {
      params,
      allData: response.data,
      data: response.data.results,
      totalRows: response.data.count,
    };
  }
);

export const appClientsSlice = createSlice({
  name: 'appClients',
  initialState: {
    data: [],
    total: 1,
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.allData = action.payload.allData;
      state.total = action.payload.totalRows;
    });
  },
});

export default appClientsSlice.reducer;
