import axios from 'axios';

const SAVE_MAIN_SIDEBAR = 'SAVE_MAIN_SIDEBAR';

export const saveMainSidebar = (value) => ({
  type: SAVE_MAIN_SIDEBAR,
  payload: value,
});

export const getMainSidebarAmounts = () => async (dispatch) => {
  const response = await axios(`/api/v1/sidebar/sidebar_data/`, {
    Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
  });
  // console.log(response);
  dispatch(saveMainSidebar(response.data));
  // localStorage.setItem('mainSidebar', JSON.stringify(response.data));
};

const initialState = {
  mainSidebar: {},
};

const saveMainSidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_MAIN_SIDEBAR: {
      return {
        ...state,
        mainSidebar: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default saveMainSidebarReducer;
