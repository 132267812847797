// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt';

const config = useJwt.jwtConfig;

const initialUser = () => {
  const item = window.localStorage.getItem('userData');
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName];
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName];
      localStorage.setItem('userData', JSON.stringify(action.payload));
      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify(action.payload.accessToken)
      );

      // здесь не нужно записывать куку!!!!

      //       document.cookie = `${config.storageRefreshTokenKeyName}=${
      //   action.payload.refreshToken
      // }; path=/; max-age=${30 * 24 * 60 * 60}`;

      // localStorage.setItem(
      //     config.storageRefreshTokenKeyName,
      //     JSON.stringify(action.payload.refreshToken)
      // );
    },
    handleLogout: (state) => {
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userData');

      // localStorage.removeItem(`${config.storageTokenKeyName}`);
      document.cookie = `${config.storageRefreshTokenKeyName}=${
        state[config.storageRefreshTokenKeyName]
      };max-age=-1;`;
      state.userData = {};
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      // localStorage.removeItem(config.storageTokenKeyName);
      // document.cookie = `${config.storageRefreshTokenKeyName}=null; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
      // localStorage.removeItem(config.storageRefreshTokenKeyName);
    },
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
