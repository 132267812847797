const SAVE_BRAND = 'SAVE_BRAND';

export const saveBrand = (value) => ({
    type: SAVE_BRAND,
    payload: value,
});

const initialState = {
    brand: '',
};

const saveBrandReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_BRAND:
            {
                return {
                    ...state,
                    brand: action.payload,
                };
            }
        default:
            {
                return state;
            }
    }
};

export default saveBrandReducer;