import axios from 'axios';
import { Redirect } from 'react-router-dom';
import jwtDefaultConfig from './jwtDefaultConfig';

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  // isRefreshed = false;

  constructor(jwtOverrideConfig) {
    if (this.getToken() && !this.getRefreshToken()) {
      this.redirectToLogin();
    }
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };
    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        // const accessToken = JSON.parse(this.getToken());
        const accessToken = this.getToken();
        const refreshToken = this.getRefreshToken();
        // ** If token is present add it to request's Authorization Header
        if (accessToken && refreshToken) {
          config.headers = {};
          config.headers['Content-Type'] = 'application/json';
          if (
            Math.floor(Date.now() / 1000) >=
              this.getExpirationTime(this.getToken()).exp - 60 &&
            !this.isAlreadyFetchingAccessToken
          ) {
            this.refreshTokenInAdvance();
            this.addSubscriber((accessToken) => {
              config.headers.Authorization = `${
                this.jwtConfig.tokenType
              } ${this.getToken()}`;
            });
          } else {
            config.headers.Authorization = `${
              this.jwtConfig.tokenType
            } ${this.getToken()}`;
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.patch['Content-Type'] = 'application/json';
    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => {
        // isRefreshed = false;
        return response;
      },
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (
            response.data.detail.includes(
              'No active account found with the given credentials'
            )
          ) {
            this.showAlert();
          } else if (response.data.detail.includes('User is inactive')) {
            this.redirectToLogin();
          } else if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken()
              .then((r) => {
                this.isAlreadyFetchingAccessToken = false;

                this.setToken(r.data.access);

                this.onAccessTokenFetched(r.data.access);
              })
              .then((r) => this.updateLoginTime());
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // console.log(originalRequest.headers['Content-Type']);
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers['Content-Type'] = 'application/json';
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  redirectToLogin() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userData');
    <Redirect to="/login" />;
  }
  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
      let [key, value] = el.split('=');
      cookie[key.trim()] = value;
    });
    return cookie[this.jwtConfig.storageRefreshTokenKeyName];
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    document.cookie = `${
      this.jwtConfig.storageRefreshTokenKeyName
    }=${value};path=/;max-age=${30 * 24 * 60 * 60}`;
    res.cookie(this.jwtConfig.storageRefreshTokenKeyName, value, {
      maxAge: 30 * 24 * 60 * 60,
      httpOnly,
    });
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refresh: this.getRefreshToken(),
    });
  }
  showAlert() {
    document.querySelector('.login-error') &&
      document.querySelector('.login-error').classList.remove('d-none');
  }

  getExpirationTime(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  updateLoginTime() {
    axios.get(`/api/v1/team/employee/update_login_time/`, {
      Authorization: this.getToken(),
    });
  }

  refreshTokenInAdvance() {
    this.isAlreadyFetchingAccessToken = true;
    this.refreshToken()
      .then((r) => {
        this.setToken(r.data.access);
        this.onAccessTokenFetched(r.data.access);

        this.isAlreadyFetchingAccessToken = false;
      })
      .then((r) => this.updateLoginTime());
  }
}
