// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import brands from '@src/views/apps/brands/store';
import clients from '@src/views/apps/clients/store';
import projects from '@src/views/apps/projects/store';
import team from '@src/views/apps/team/store';
import email from './emailStore';
import brand from './brandStore';
import brandsList from './brandsList';
import mainSidebar from './mainSidebar';
import tasks from '@src/views/apps/projects/tasksTracker/store';

const rootReducer = {
  brand,
  email,
  brandsList,
  auth,
  navbar,
  layout,
  mainSidebar,
  brands,
  clients,
  team,
  projects,
  tasks,
};

export default rootReducer;
